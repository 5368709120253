import type { NextPage } from 'next';
import { memo } from 'react';
import { Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { TableColumn } from '../../types/Common.interfaces';
import { AudienceFilter, Filter } from '../../types/Cube.interfaces';
import AdvancedFilterItem from '../advancedFilterItem/advancedFilterItem';
import AdvancedFilterGroupRelationSwitch from '../advancedFilterGroupRelationSwitch/advancedFilterGroupRelationSwitch';

  interface Props {
    filters: Filter[];
    audienceFiltersGroup: AudienceFilter[];
    audienceFilters: AudienceFilter[];
    groupIndex: number;
    columns: TableColumn[];
    groupRelation: 'and'| 'or';
    setAudienceFilters: (value: AudienceFilter[]) => void;
    handleAddFilterToGroup: (groupIndex: number, newFilter: AudienceFilter) => void;
    handleDeleteFilterGroup: (groupIndex: number) => void;
    handleDeleteFilterInGroup: (groupIndex: number, filterIndex: number) => void;
    onUpdateFilterInGroup: (groupIndex: number, filterIndex: number, updatedFilter: AudienceFilter, shouldTriggerCalls: boolean) => void;
    setFilterChanged?: (value: boolean) => void;
    handleGroupRelationChange: (relation: 'and' | 'or', groupIndex: number) => void;
  }

const AdvancedFilterGroup: NextPage<Props> = ({
  filters,
  columns,
  audienceFiltersGroup,
  audienceFilters,
  groupIndex,
  groupRelation,
  handleAddFilterToGroup,
  handleDeleteFilterGroup,
  handleDeleteFilterInGroup,
  setAudienceFilters,
  onUpdateFilterInGroup,
  setFilterChanged,
  handleGroupRelationChange
}) => {

  const onDeleteGroupClicked = () => {
    handleDeleteFilterGroup(groupIndex);
  };

  const addNewFilterToGroup = () => {
    const newFilter: AudienceFilter = { member: '', operator: 'equals', values: [] };
    handleAddFilterToGroup(groupIndex, newFilter);
  };

  const onGroupRelationChange = (newRelation: 'and' | 'or') => {
    handleGroupRelationChange(newRelation, groupIndex);
  };


  return (
    <Box
      className='filter-group u-flex u-br-10-px u-my-12 u-pl-12'
      sx={{ paddingRight: '200px' }}
    >
      {audienceFiltersGroup.length > 1 ? (
        <AdvancedFilterGroupRelationSwitch
          relation={groupRelation}
          onToggle={onGroupRelationChange}
        />
      ): null }
      <Box>
        {audienceFiltersGroup.map((filterItem: AudienceFilter, index: number) => {
          return filterItem.is_group ? (
            <AdvancedFilterGroup
              key={`group-filter-${index}`}
              groupIndex={index}
              filters={filters}
              audienceFiltersGroup={audienceFiltersGroup}
              audienceFilters={audienceFilters}
              columns={columns}
              groupRelation={filterItem.relation!}
              setAudienceFilters={setAudienceFilters}
              onUpdateFilterInGroup={onUpdateFilterInGroup}
              setFilterChanged={setFilterChanged}
              handleDeleteFilterInGroup={handleDeleteFilterInGroup}
              handleAddFilterToGroup={handleAddFilterToGroup}
              handleDeleteFilterGroup={()=>handleDeleteFilterGroup(groupIndex)}
              handleGroupRelationChange={handleGroupRelationChange}
            />
          ) : (
            <AdvancedFilterItem
              key={`filter-in-group-${groupIndex}-${index}}`}
              index={index}
              isInGroup={true}
              groupIndex={groupIndex}
              audienceFilter={filterItem}
              audienceFilters={audienceFilters}
              setAudienceFilters={setAudienceFilters}
              filters={filters}
              columns={columns}
              handleDeleteFilter={() => handleDeleteFilterInGroup(groupIndex, index)}
              onUpdateFilterInGroup={onUpdateFilterInGroup}
              setFilterChanged={setFilterChanged}
            />
          );
        })}
      </Box>
      <Box
        className='u-p-4 filter-groups-actions-popover'
      >
        <Button
          disableElevation
          startIcon={<AddIcon />}
          className='contained-button u-mr-16 u-text-transform-none filter-action-button-contained u-fw-400'
          variant='contained'
          onClick={addNewFilterToGroup}
        >
          Add Filter
        </Button>
        <Button
          disableElevation
          startIcon={<DeleteOutlineOutlinedIcon />}
          className='outlined-button u-text-transform-none filter-action-button-outlined u-fw-400'
          variant='outlined'
          onClick={onDeleteGroupClicked}
        >
          Delete Group
        </Button>
      </Box>
    </Box>
  );
};

export default memo(AdvancedFilterGroup);