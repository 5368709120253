import type { NextPage } from 'next';
import { Modal, Box, Fade, Typography, Button } from '@mui/material';

interface Props {
  open: boolean;
  closeFilterModal: () => void;
  getData: () => void;
}

const CloseFiltersConfirmationModal: NextPage<Props> = ({ open, closeFilterModal, getData }) => {

  const handleConfirmationModal = (applyChanges: boolean) => {
    if (applyChanges) {
      getData();
    }
    closeFilterModal();
  };


  return (
    <Modal
      open={open}
      onClose={() => handleConfirmationModal(false)}
    >
      <Fade in={open}>
        <Box className='modal' sx={{ maxWidth: '600px' }}>
          <Box className='u-relative u-p-32'>
            <Typography className='fs-title-large u-fw-500 u-pb-24'>
              Ooops!
            </Typography>
            <Box>
              <Typography>
                It seems some filters have been set up but not applied to this query. Would you like to apply them before closing the window?
              </Typography>
            </Box>
            <Box className='u-flex u-flex-justify-end u-pt-24'>
              <Button
                variant='outlined'
                className='outlined-button u-w-100-px u-mr-12 u-text-transform-none'
                onClick={() => handleConfirmationModal(false)}
              >
                No
              </Button>
              <Button
                variant='contained'
                className='contained-button u-w-100-px u-text-transform-none'
                onClick={() => handleConfirmationModal(true)}
              >
                Yes
              </Button>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default CloseFiltersConfirmationModal;
