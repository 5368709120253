import { ChangeEvent, HTMLAttributes, SyntheticEvent, useState, useRef, useEffect } from 'react';
import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useDebouncedCallback } from 'use-debounce';
import { Query, ResultSet } from '@cubejs-client/core';
import { useGlobalState } from '../../context/globalState';
import { useUiState } from '../../context/uiState';
import { useBasicFiltersState } from '../../context/basicFiltersState';
import LoadingFilterError from '../loadingFilterError/loadingFilterError';
import AdvancedFilters from '../advancedFilters/advancedFilters';
import SearchFilterIndicator from '../searchFilterIndicator/searchFilterIndicator';
import EmptyFilter from '../emptyFilter/emptyFilter';
import { FilterOption, Segment, TableColumn } from '../../types/Common.interfaces';
import {
  Box,
  InputAdornment,
  Paper,
  TextField,
  Autocomplete,
  AutocompleteChangeReason,
  Button,
  AutocompleteRenderInputParams,
  IconButton,
  Typography,
  Tooltip
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import { AudienceFilter } from '../../types/Cube.interfaces';
import useCubejsApiWrapper from '../../apiHelpers/cubejsWrapper';

interface Props {
  isDatabasePage?: boolean;
  clearFilters?: boolean;
  setFilterChanged?: (value: boolean) => void;
  audienceFilters?: AudienceFilter[];
  setAudienceFilters: (value: AudienceFilter[]) => void;
  handleClearFilters?: () => void;
  segment?: Segment | null;
  currentTab?: string;
  dateFilterChanged?: boolean;
  setDateFilterChanged?: (value: boolean) => void;
  segmentPage?: boolean;
  shouldRedirect?: boolean;
  companyTotalCount?: number;
  peopleTotalCount?: number;
  loading?: boolean;
  getData?: () => void;
  openConfirmationModal?: boolean;
  setOpenConfirmationModal?: (value: boolean) => void;
  filterChangesApplied?: boolean;
  setFilterChangesApplied?: (value: boolean) => void;
  totalPersons?: number;
  totalCompanies?: number;
};

const SearchBar: NextPage<Props> = ({
  isDatabasePage, clearFilters, setFilterChanged, audienceFilters, setAudienceFilters, dateFilterChanged, segmentPage,
  handleClearFilters, segment, currentTab, setDateFilterChanged, shouldRedirect, companyTotalCount, peopleTotalCount, loading, getData,
  totalPersons, totalCompanies
}) => {
  const router = useRouter();
  const { defaultRowsPerPage, companyDatabaseColumns, searchEntity, setSearchEntity, capitalize, organizationData } = useGlobalState();
  const { setSearchBarHeight } = useUiState();
  const { modifyJobTitleFilter } = useBasicFiltersState();
  const [searchValue, setSearchValue] = useState<string>('');
  const [label, setLabel] = useState<string>('');
  const [filterOptions, setFilterOptions] = useState<FilterOption[]>([]);
  const [placeholder, setPlaceholder] = useState<string>('Search Organizations');
  const [searchError, setSearchError] = useState<boolean>(false);
  const [searching, setSearching] = useState<boolean>(false);
  const [filterSearched, setFilterSearched] = useState<boolean>(false);
  const [searchInputHeight, setSearchInputHeight] = useState<number>(0);
  const [personCount, setPersonCount] = useState<number>(0);
  const [companyCount, setCompanyCount] = useState<number>(0);
  const searchBarRef = useRef<any>();
  const inputRef = useRef<any>();
  const cubejsApiWrapper = useCubejsApiWrapper();

  useEffect(() => {
    if (searchBarRef && searchBarRef.current) {
      setSearchBarHeight(searchBarRef.current.clientHeight);
    }
  }, [searchBarRef, setSearchBarHeight]);

  useEffect(() => {
    if (organizationData) {
      setSearchValue('');
      setFilterOptions([]);
      setPlaceholder(`Search ${capitalize(searchEntity)}`);
      setLabel('');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationData]);

  useEffect(() => {
    if (clearFilters) {
      setFilterSearched(false);
    }
  }, [clearFilters]);

  useEffect(() => {
    if (inputRef && inputRef.current) {
      setSearchInputHeight(inputRef.current.clientHeight);
    }
  }, [inputRef]);

  const handleFocus = () => {
    setLabel(`Search ${capitalize(searchEntity)}`);
    setPlaceholder('');
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    if (!event.target.value) {
      setLabel('');
      setPlaceholder(`Search ${capitalize(searchEntity)}`);
    }
  };

  const handleChangeSearchEntity = (entity: string) => {
    setFilterOptions([]);
    setFilterSearched(false);
    setSearchEntity(entity);
    setPlaceholder(`Search ${capitalize(entity)}`);
  };

  const  handleChangeFilterValue = (item: FilterOption | string, reason: AutocompleteChangeReason) => {
    switch (reason) {
      case 'createOption':
        break;
      case 'selectOption':
        setSearchValue('');
        setFilterOptions([]);
        setFilterSearched(false);
        if (searchEntity === 'organizations') {
          if (segmentPage) {
            setAudienceFilters([
              ...audienceFilters || [],
              {
                member: 'COMPANY.company_name_org',
                operator: 'equals',
                values: [item['COMPANY.company_name_org']]
              },
              {
                member: 'COMPANY.domain_org',
                operator: 'equals',
                values: [item['COMPANY.domain_org']]
              }
            ]);
          } else {
            setAudienceFilters([
              {
                member: 'COMPANY.company_name_org',
                operator: 'equals',
                values: [item['COMPANY.company_name_org']]
              },
              {
                member: 'COMPANY.domain_org',
                operator: 'equals',
                values: [item['COMPANY.domain_org']]
              }
            ]);
            if (shouldRedirect) {
              router.push(`/database?page=1&page-size=${defaultRowsPerPage}&tab=organizations`);
            }
          }
        } else if (searchEntity === 'people') {
          if (segmentPage) {
            setAudienceFilters([
              ...audienceFilters || [],
              {
                member: 'TAM.name_per',
                operator: 'equals',
                values: [item['TAM.name_per']]
              }
            ]);
          } else {
            setAudienceFilters([
              {
                member: 'TAM.name_per',
                operator: 'equals',
                values: [item['TAM.name_per']]
              }
            ]);
            if (shouldRedirect) {
              router.push(`/database?page=1&page-size=${defaultRowsPerPage}&tab=people`);
            }
          }
        }
        if (setFilterChanged) {
          setFilterChanged(true);
          setTimeout(() => {
            setFilterChanged(false);
          }, 100);
        }
        break;
      case 'removeOption':
        break;
      case 'clear':
        break;
      default:
        break;
    }
  };

  const findIndustryColumn = (): string => {
    const findColumn = companyDatabaseColumns.find((column: TableColumn) => (
      column.columnName === 'industry_linkedin_org' || column.columnName === 'linkedin_industry_org'
    ));
    return findColumn ? findColumn.columnName : '';
  };

  const handleFilterOptions = async (search: string) => {
    if (search.length > 0) {
      try {
        setSearchError(false);
        // setSearching(true);
        const additionalFilters = segmentPage ? modifyJobTitleFilter(audienceFilters || []) : [];
        if (searchEntity === 'organizations') {
          const linkedinColumn = findIndustryColumn();
          const queries: Query[] = [];
          queries.push({
            measures: ['COMPANY.countDistinct'],
            dimensions: ['COMPANY.company_name_org', 'COMPANY.rbid_org', 'COMPANY.domain_org', linkedinColumn ? `COMPANY.${linkedinColumn}` : ''],
            filters: [
              ...additionalFilters,
              {
                member: 'COMPANY.company_name_org',
                operator: 'startsWith',
                values: [ search ],
              }
            ],
            limit: 5,
          }, {
            measures: ['COMPANY.countDistinct'],
            filters: [
              ...additionalFilters,
              {
                member: 'COMPANY.company_name_org',
                operator: 'startsWith',
                values: [ search ],
              }
            ],
          });
          const [resultDataSet, countDataSet]: ResultSet[] = await Promise.all(queries.map((item: Query) => cubejsApiWrapper(item)));
          setFilterOptions(resultDataSet.tablePivot().map((value: any) => (
            { ...value, type: 'Organizations' }
          )));
          setCompanyCount(+countDataSet.tablePivot()[0]['COMPANY.countDistinct']);
        } else if (searchEntity === 'people') {
          const queries: Query[] = [];
          queries.push({
            measures: ['TAM.countDistinct'],
            dimensions: ['TAM.name_per', 'TAM.full_name_per', 'TAM.job_title_per', 'TAM.rbid_pao', 'COMPANY.company_name_org'],
            filters: [
              ...additionalFilters,
              {
                member: 'TAM.full_name_per',
                operator: 'startsWith',
                values: [ search ],
              }
            ],
            limit: 10,
          }, {
            measures: ['TAM.countDistinct'],
            filters: [
              ...additionalFilters,
              {
                member: 'TAM.full_name_per',
                operator: 'startsWith',
                values: [ search ],
              }
            ],
          });
          const [resultDataSet, countDataSet]: ResultSet[] = await Promise.all(queries.map((item: Query) => cubejsApiWrapper(item)));
          setFilterOptions(resultDataSet.tablePivot().map((value: any) => (
            { ...value, type: 'People' }
          )));
          setPersonCount(+countDataSet.tablePivot()[0]['TAM.countDistinct']);
        }
      } catch (error) {
        setFilterOptions([]);
        setSearchError(true);
      } finally {
        setSearching(false);
        setFilterSearched(true);
      }
    } else {
      setSearching(false);
      setFilterOptions([]);
      setFilterSearched(false);
    }
  };

  const debouncedSearch = useDebouncedCallback(handleFilterOptions, 700);

  const getOptionLabel = (item: string | {[key: string]: string }): string => {
    if (typeof item === 'string') {
      return item;
    } else if (searchEntity === 'organizations') {
      return item['COMPANY.company_name_org'];
    } else if (searchEntity === 'people') {
      return item['TAM.full_name_per'];
    } else {
      return '';
    }
  };

  const handleAddSearchFilter = () => {
    if (searchEntity === 'people') {
      if (segmentPage) {
        setAudienceFilters([
          ...audienceFilters || [],
          {
            member: 'TAM.name_per',
            operator: 'startsWith',
            values: [searchValue]
          }
        ]);
      } else {
        setAudienceFilters([
          {
            member: 'TAM.name_per',
            operator: 'startsWith',
            values: [searchValue]
          }
        ]);
        if (shouldRedirect) {
          router.push(`/database?page=1&page-size=${defaultRowsPerPage}&tab=people`);
        }
      }
    } else if (searchEntity === 'organizations') {
      if (segmentPage) {
        setAudienceFilters([
          ...audienceFilters || [],
          {
            member: 'COMPANY.company_name_org',
            operator: 'startsWith',
            values: [searchValue]
          }
        ]);
      } else {
        setAudienceFilters([
          {
            member: 'COMPANY.company_name_org',
            operator: 'startsWith',
            values: [searchValue]
          }
        ]);
        if (shouldRedirect) {
          router.push(`/database?page=1&page-size=${defaultRowsPerPage}&tab=organizations`);
        }
      }
    }
    setSearchValue('');
    setFilterOptions([]);
    setFilterSearched(false);
    if (setFilterChanged) {
      setFilterChanged(true);
      setTimeout(() => {
        setFilterChanged(false);
      }, 100);
    }
  };

  return (
    <Box className='u-py-20 u-px-40 u-flex u-flex-space-between u-flex-align-stretch search-bar-wrapper' ref={searchBarRef}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography className='fs-title-regular u-pr-24'>Search</Typography>
        <Tooltip title='Search organizations' placement='bottom-start'>
          <Box
            className='u-px-16 u-py-12 u-cursor-pointer'
            onClick={() => handleChangeSearchEntity('organizations')}
            sx={{
              background: searchEntity === 'organizations' ? '#232f3e' : '#fff',
              borderRadius: '4px 0 0 4px',
              border: '1px solid #757575',
              borderRight: 'none',
              height: `${searchInputHeight}px`,
              minHeight: '55px'
            }}
          >
            <ApartmentRoundedIcon sx={{ color: searchEntity === 'organizations' ? '#fff' : '#757575'}} />
          </Box>
        </Tooltip>
        <Tooltip title='Search people' placement='bottom-start'>
          <Box
            className='u-px-16 u-py-12 u-cursor-pointer'
            onClick={() => handleChangeSearchEntity('people')}
            sx={{
              background: searchEntity === 'people' ? '#232f3e' : '#fff',
              borderTop: '1px solid #757575',
              borderBottom: '1px solid #757575',
              height: `${searchInputHeight}px`,
              minHeight: '55px'
            }}
          >
            <GroupsRoundedIcon sx={{ color: searchEntity === 'people' ? '#fff' : '#757575'}} />
          </Box>
        </Tooltip>
        <Autocomplete
          freeSolo
          className='search'
          onChange={(event: SyntheticEvent<Element, Event>, item: FilterOption | string, reason: AutocompleteChangeReason) => (
            handleChangeFilterValue(item, reason)
          )}
          options={filterOptions}
          getOptionLabel={(item: string | FilterOption) => getOptionLabel(item)}
          groupBy={(option: FilterOption): string => option.type!}
          inputValue={searchValue}
          selectOnFocus
          handleHomeEndKeys
          renderOption={(props: HTMLAttributes<HTMLLIElement>, option: FilterOption) => (
            <li {...props} key={searchEntity === 'organizations' ? option['COMPANY.rbid_org'] : option['TAM.rbid_pao']}>
              {searchEntity === 'organizations' ? (
                <Typography className='u-fw-700'>
                  {option['COMPANY.company_name_org']}
                  {findIndustryColumn() && option[`COMPANY.${findIndustryColumn()}`] ? (
                    <Box component='span' className='u-ml-16 u-fw-400' sx={{ color: '#ABABAE' }}>
                      {option[`COMPANY.${findIndustryColumn()}`]}
                    </Box>
                  ) : null}
                </Typography>
              ) : searchEntity === 'people' ? (
                <Typography className='u-fw-700'>
                  {option['TAM.full_name_per']}
                  {option['TAM.job_title_per'] ? (
                    <Box component='span' className='u-ml-16 u-fw-400' sx={{ color: '#ABABAE' }}>
                      {option['TAM.job_title_per']}
                    </Box>
                  ) : null}
                  {option['COMPANY.company_name_org'] ? (
                    <Box component='span' className='u-ml-16 u-fw-400'>
                      {option['COMPANY.company_name_org']}
                    </Box>
                  ) : null}
                </Typography>
              ) : null}
            </li>
          )}
          sx={{ width: 500 }}
          disableClearable
          PaperComponent={({ children }) => (
            <Paper
              className='search-dropdown u-br-14-px'
              sx={{ width: '65vw', maxWidth: '800px', overflow: 'hidden' }}
              onClick={(e: any) => e.preventDefault()}
            >
              {searching ? (
                <SearchFilterIndicator size='big' />
              ) : (
                searchError ? (
                  <LoadingFilterError />
                ) : (
                  filterSearched ? (
                    filterOptions.length ? (
                      <>
                        {children}
                        {searchEntity === 'people' && personCount ? (
                          <Box
                            className='u-px-16 u-py-8 u-cursor-pointer search-all'
                            sx={{ borderTop: '1px solid #E2E2E6' }}
                            onMouseDown={() => handleAddSearchFilter()}
                          >
                            <Typography sx={{ color: '#00b5b0' }}>
                              Show all {personCount.toLocaleString()} people that start with “{searchValue}”
                            </Typography>
                          </Box>
                        ) : null}
                        {searchEntity === 'organizations' && companyCount ? (
                          <Box
                            className='u-px-16 u-py-8 u-cursor-pointer search-all'
                            sx={{ borderTop: '1px solid #E2E2E6' }}
                            onMouseDown={() => handleAddSearchFilter()}
                          >
                            <Typography sx={{ color: '#00b5b0' }}>
                              Show all {companyCount.toLocaleString()} organizations that start with “{searchValue}”
                            </Typography>
                          </Box>
                        ) : null}
                      </>
                    ) : (
                      <EmptyFilter/>
                    )
                  ) : (
                    <>
                      {children}
                      {searchEntity === 'people' && filterOptions.length && personCount ? (
                        <Box
                          className='u-px-16 u-py-8 u-cursor-pointer search-all'
                          sx={{ borderTop: '1px solid #E2E2E6' }}
                          onMouseDown={() => handleAddSearchFilter()}
                        >
                          <Typography sx={{ color: '#00b5b0' }}>
                            Show all {personCount.toLocaleString()} people that start with “{searchValue}”
                          </Typography>
                        </Box>
                      ) : null}
                      {searchEntity === 'organizations' && filterOptions.length && companyCount ? (
                        <Box
                          className='u-px-16 u-py-8 u-cursor-pointer search-all'
                          sx={{ borderTop: '1px solid #E2E2E6' }}
                          onMouseDown={() => handleAddSearchFilter()}
                        >
                          <Typography sx={{ color: '#00b5b0' }}>
                            Show all {companyCount.toLocaleString()} organizations that start with “{searchValue}”
                          </Typography>
                        </Box>
                      ) : null}
                    </>
                  )
                )
              )}
            </Paper>
          )}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              ref={inputRef}
              value={searchValue}
              label={label}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                if (event.target.value.length) {
                  setSearching(true);
                }
                setSearchValue(event.target.value);
                debouncedSearch(event.target.value);
              }}
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  searchValue.length ? (
                    <InputAdornment
                      position='end'
                      onClick={() => {
                        setSearchValue('');
                        setFilterOptions([]);
                        setFilterSearched(false);
                        setSearchError(false);
                        setSearching(false);
                      }}
                    >
                      <IconButton size='small'>
                        <HighlightOffIcon />
                      </IconButton>
                    </InputAdornment>
                  ) : null
                ),
                sx: {
                  borderRadius: '0 4px 4px 0'
                }
              }}
            />
          )}
        />
        {isDatabasePage && (
          <Box>
            <AdvancedFilters
              audienceFilters={audienceFilters || []}
              setFilterChanged={setFilterChanged}
              setAudienceFilters={setAudienceFilters}
              segment={segment}
              companyTotalCount={companyTotalCount}
              peopleTotalCount={peopleTotalCount}
              loading={loading}
              getData={getData}
              totalPersons={totalPersons}
              totalCompanies={totalCompanies}
            />
          </Box>
        )}
        {audienceFilters && audienceFilters.length > 0 ? (
          <Box
            component='span'
            className='clear-filters-header'
            onClick={() => handleClearFilters && handleClearFilters()}
          >
            Clear All Filters
          </Box>
        ) : null}
      </Box>
      {!isDatabasePage && (
        <Button
          className='contained-button u-text-transform-none'
          variant='contained'
          onClick={() => router.push(`/database?page=1&page-size=${defaultRowsPerPage}&tab=organizations`)}
          aria-describedby='filters-popover'
        >
          View Full Database
        </Button>
      )}
    </Box>
  );
};

export default SearchBar;