import type { NextPage } from 'next';
import { memo, useEffect, useState } from 'react';
import { Box, AutocompleteChangeReason, Chip, FormControlLabel, Checkbox, Autocomplete, Paper, TextField } from '@mui/material';
import { AudienceFilter, Filter } from '../../types/Cube.interfaces';
import { TableColumn } from '../../types/Common.interfaces';
import SearchFilterIndicator from '../searchFilterIndicator/searchFilterIndicator';

interface Props {
  filter: Filter;
  audienceFilter: AudienceFilter;
  options: any;
  column: TableColumn | null;
  loading: boolean;
  searchError: boolean;
  handleChangeFilterValue: (items: any, reason: AutocompleteChangeReason) => void;
  loadOptions: () => void;
}

const PersonaFilter: NextPage<Props> = ({
  filter,
  audienceFilter,
  options,
  column,
  loading,
  handleChangeFilterValue,
  loadOptions
}) => {
  const [filterOptions, setFilterOptions] = useState<any[]>([]);
  const [selectedPersonas, setSelectedPersonas] = useState<string[]>(audienceFilter.values);

  useEffect(() => {
    if (filter) {
      setFilterOptions(options);
    }
  }, [options, filter, column]);

  useEffect(() => {
    setSelectedPersonas(audienceFilter.values);
  }, [audienceFilter]);

  useEffect(() => {
    const newCheckedStatus: {[key: string]: boolean} = {};
    options.forEach((group: any) => {
      const allChildrenSelected = group.values.every((value: string) => selectedPersonas.includes(value));
      newCheckedStatus[group.label] = allChildrenSelected;
      group.values.forEach((value: string) => {
        newCheckedStatus[value] = selectedPersonas.includes(value);
      });
    });
    setCheckedStatus(newCheckedStatus);
  }, [selectedPersonas, options]);

  const [checkedStatus, setCheckedStatus] = useState<{[key: string]: boolean}>({});

  const handleValueToggle = (value: string) => {
    const newSelectedPersonas = selectedPersonas.includes(value)
      ? selectedPersonas.filter(v => v !== value)
      : [...selectedPersonas, value];
    setSelectedPersonas(newSelectedPersonas);
    handleChangeFilterValue(newSelectedPersonas, 'selectOption');
  };

  const handleGroupToggle = (groupLabel: string) => {
    const group = options.find((g: any) => g.label === groupLabel);
    if (!group) return;
    let newSelectedPersonas;
    if (checkedStatus[groupLabel]) {
      newSelectedPersonas = selectedPersonas.filter(persona => !group.values.includes(persona));
    } else {
      newSelectedPersonas = [...new Set([...selectedPersonas, ...group.values])];
    }
    setSelectedPersonas(newSelectedPersonas);
    handleChangeFilterValue(newSelectedPersonas, 'selectOption');
  };

  const CustomPaper = (props: any) => {
    if (loading) {
      return (
        <Paper {...props}>
          <SearchFilterIndicator size="regular" />
        </Paper>
      );
    }
    return <Paper sx={{ width: 'fit-content', maxWidth: '400px', minWidth: '200px' }} {...props} />;
  };


  return (
    <Autocomplete
      multiple
      freeSolo
      className='u-w-200-px adv-filter-input'
      options={filterOptions}
      disabled={!column}
      value={selectedPersonas}
      disableCloseOnSelect
      getOptionLabel={(option: any) => option.label}
      filterOptions={(options) => options}
      onChange={(event: React.ChangeEvent<{}>, items: any, reason: AutocompleteChangeReason) => handleChangeFilterValue(items, reason)}
      onOpen={() => {
        if (filterOptions.length === 0) {
          loadOptions();
        }
      }}
      PaperComponent={CustomPaper}
      renderOption={(props: any, item: any, { selected }) => {
        return (
          <Box
            key={item.label}
            className='u-pl-20 u-pr-20'
            sx={{ width: 'fit-content', maxWidth: '400px' }}
          >
            <FormControlLabel
              className='checkbox-button u-white-space-nowrap'
              checked={checkedStatus[item.label]}
              onClick={() => handleGroupToggle(item.label)}
              control={
                <Checkbox
                  inputProps={{ 'aria-label': 'controlled' }}
                  classes={{
                    root: 'checkbox-root'
                  }}
                />
              }
              classes={{
                label: 'u-fw-700'
              }}
              label={item.label}
              name={item.label}
              value={item.label}
            />
            <Box className='u-flex u-flex-direction-column u-pl-20'>
              {item.values.map((value: string) => (
                item.label !== value &&
                <FormControlLabel
                  key={value}
                  className='checkbox-button u-flex-100'
                  checked={checkedStatus[value]}
                  onClick={() => handleValueToggle(value)}
                  control={
                    <Checkbox
                      inputProps={{ 'aria-label': 'controlled' }}
                      classes={{
                        root: 'checkbox-root'
                      }}
                    />
                  }
                  label={value}
                  name={value}
                  value={value}
                />
              ))}
            </Box>
          </Box>
        );
      }}
      renderTags={(tagValue) => [
        ...tagValue.map((option, index) => (
          <Chip
            key={index}
            label={option}
            size="small"
            color='primary'
            className='u-mb-2'
            onDelete={(event) => {
              handleValueToggle(option);
            }}
          />
        )),
        tagValue.length > 1 && (
          <Chip
            key="clear_all"
            label="Clear All"
            onClick={(event) => {
              handleChangeFilterValue([], 'clear');
            }}
            sx={{ backgroundColor: '#FFF', border: '1px solid #2F3B4A80' }}
            size="small"
            className='u-ml-4'
          />
        )
      ]}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Select Value'
          variant="outlined"
          size="medium"
          className='autocomplete-label'
          onKeyDown={(event: any)=> {
            event.preventDefault();
            return false;
          }}
          InputLabelProps={{
            shrink: !!column
          }}
        />
      )}
    />
  );
};

export default memo(PersonaFilter);
