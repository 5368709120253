import type { NextPage } from 'next';
import { memo } from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';

interface Props {
    companyTotalCount?: number;
    peopleTotalCount?: number;
    loading?: boolean;
}

const AdvancedFiltersCounter: NextPage<Props> = ({companyTotalCount, peopleTotalCount, loading, }) => {

  return (
    <>
      <Box
        className='u-flex u-px-32 u-py-20'
        sx={{ borderTop: '1px solid #E2E2E6', borderBottom: '1px solid #E2E2E6' }}
      >
        <Box className='u-mr-12'>
          <Box className='u-flex'>
            <Typography className='text-color-lighter'>Total Records:</Typography>
            <Box component='span' className='u-fw-700 u-ml-8 u-flex u-flex-align-center'>
              {loading ? (
                <Box className='u-absolute u-w-200-px'>
                  <LinearProgress
                    className='linear-progress'
                    sx={{bottom: '-2px !important'}}
                    variant='indeterminate'
                  />
                </Box>
              ) : (
                <>
                  <Box className='u-flex'>
                    <Typography>{companyTotalCount?.toLocaleString()}</Typography>
                    <Typography className='text-color-lighter u-ml-8'>Organizations</Typography>
                  </Box>
                  <Box className='u-flex u-ml-64'>
                    <Typography>{peopleTotalCount?.toLocaleString()} </Typography>
                    <Typography className='text-color-lighter u-ml-8'>People</Typography>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default memo(AdvancedFiltersCounter);
