import type { NextPage } from 'next';
import { useEffect, useRef, useState, memo } from 'react';
import { Dialog, Box, Button } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { Segment } from '../../types/Common.interfaces';
import { AudienceFilter } from '../../types/Cube.interfaces';
import AdvancedFiltersHeader from '../advancedFiltersHeader/advancedFiltersHeader';
import AdvancedFiltersCounter from '../advancedFiltersCounter/advancedFiltersCounter';
import AdvancedFiltersFooter from '../advancedFiltersFooter/advancedFiltersFooter';
import AdvanceFiltersWrapper from '../advancedFiltersWrapper/advancedFiltersWrapper';
import CloseFiltersConfirmationModal from '../closeFiltersConfirmationModal/closeFiltersConfirmationModal';

interface Props {
  audienceFilters: AudienceFilter[];
  segment?: Segment | null;
  companyTotalCount?: number;
  peopleTotalCount?: number;
  loading?: boolean;
  setFilterChanged?: (value: boolean) => void;
  setAudienceFilters: (value: AudienceFilter[]) => void;
  getData?: () => void;
  totalPersons?: number;
  totalCompanies?: number;
}

const AdvancedFilters: NextPage<Props> = ({
  audienceFilters, setAudienceFilters, companyTotalCount, peopleTotalCount, loading, setFilterChanged, getData, totalPersons, totalCompanies
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | null>(null);
  const [initialAudienceFilters, setInitialAudienceFilters] = useState<AudienceFilter[]>([]);
  const buttonRef = useRef<any>();
  const iconRef = useRef<any>();
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);

  useEffect(() => {
    setInitialAudienceFilters([...audienceFilters]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseModal = () => {
    setOpenConfirmationModal!(false);
    setAnchorEl(null);
  };

  const handleGetData = () => {
    if (getData) {
      getData();
    }
    setAnchorEl(null);
  };

  const handleResetFilters = () => {
    setAudienceFilters(initialAudienceFilters);
    if (setFilterChanged) {
      setFilterChanged(true);
      setTimeout(() => setFilterChanged(false), 100);
    }
  };

  const handleCancel = () => {
    if (totalPersons === peopleTotalCount && totalCompanies === companyTotalCount) {
      setAnchorEl(null);
    } else {
      setOpenConfirmationModal(true);
    }
  };


  return (
    <>
      <Button
        className={
          `u-text-transform-none filters-button outlined-button ${audienceFilters && audienceFilters.length || anchorEl ? 'focused' : ''}`
        }
        variant='outlined'
        startIcon={<FilterListIcon ref={iconRef} />}
        onClick={(event: React.MouseEvent) => setAnchorEl(anchorEl ? null : buttonRef.current)}
        ref={buttonRef}
      >
        Filters
        { audienceFilters.length ?  <DoneOutlinedIcon className='u-ml-4 fs-title-medium' /> : null }
      </Button>
      <Dialog
        fullScreen
        sx={{
          maxHeight: '95vh',
          top: '75px'
        }}
        id='filters-popover'
        open={!!anchorEl}
      >
        <Box
          className='u-flex u-flex-direction-column'
          sx={{
            width: '100vw',
            height: '100%'
          }}
        >
          <AdvancedFiltersHeader />
          <AdvancedFiltersCounter
            companyTotalCount={companyTotalCount}
            peopleTotalCount={peopleTotalCount}
            loading={loading}
          />
          <AdvanceFiltersWrapper
            setFilterChanged={setFilterChanged}
            audienceFilters={audienceFilters}
            setAudienceFilters={setAudienceFilters}
          />
          <AdvancedFiltersFooter
            getData={handleGetData}
            onResetFilters={handleResetFilters}
            handleCancel={handleCancel}
          />
        </Box>
      </Dialog>
      <CloseFiltersConfirmationModal
        open={openConfirmationModal!}
        getData={handleGetData}
        closeFilterModal={handleCloseModal}
      />
    </>
  );
};

export default memo(AdvancedFilters);
