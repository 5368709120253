import type { NextPage } from 'next';
import { memo } from 'react';
import { Box, Button } from '@mui/material';

interface Props {
  getData: () => void;
  onResetFilters: () => void;
  handleCancel: () => void;
}

const AdvancedFiltersFooter: NextPage<Props> = ({ getData, onResetFilters, handleCancel }) => {


  return (
    <Box
      className='u-flex u-flex-space-between u-px-40 u-py-20 filters-modal-footer-bg'
      sx={{ marginTop: 'auto'}}
    >
      <Button
        variant='outlined'
        className='outlined-button u-px-24'
        sx={{ backgroundColor: '#FFF' }}
        onClick={onResetFilters}
      >
        Reset
      </Button>
      <Box>
        <Button
          variant='outlined'
          className='outlined-button u-mr-12 u-ml-16 u-px-24'
          sx={{ backgroundColor: '#FFF' }}
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          className='contained-button u-text-transform-none u-px-24'
          variant='contained'
          onClick={getData}
        >
          Apply
        </Button>
      </Box>
    </Box>
  );
};

export default memo(AdvancedFiltersFooter);
