import type { NextPage } from 'next';
import { useState, useEffect, memo } from 'react';
import { Autocomplete, TextField, Chip, AutocompleteChangeReason, MenuItem, Box, Paper } from '@mui/material';
import { AudienceFilter, Filter } from '../../types/Cube.interfaces';
import { TableColumn } from '../../types/Common.interfaces';
import SearchFilterIndicator from '../searchFilterIndicator/searchFilterIndicator';
import LoadingFilterError from '../loadingFilterError/loadingFilterError';
import EmptyFilter from '../emptyFilter/emptyFilter';

interface Props {
  filter: Filter;
  audienceFilter: AudienceFilter;
  options: any;
  column: TableColumn | null;
  loading: boolean;
  searchError: boolean;
  filterSearched: boolean;
  handleChangeFilterValue: (items: any, reason: AutocompleteChangeReason) => void;
  loadOptions: (searchValue: string, isTextFilter: boolean) => void;
}

const TextFilter: NextPage<Props> = ({
  filter,
  audienceFilter,
  options,
  column,
  loading,
  searchError,
  filterSearched,
  handleChangeFilterValue,
  loadOptions,
}) => {
  const [value, setValue] = useState<any[]>([]);
  const [filterOptions, setFilterOptions] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  useEffect(() => {
    if (audienceFilter && filter) {
      setValue([...audienceFilter.values.map((value: string) => ({ [filter.value]: value }))]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audienceFilter.values, filter]);

  useEffect(() => {
    if (filter) {
      const newFilterOptions = options.filter(
        (item: { [key: string]: string }) => item[filter.value]
      );
      setFilterOptions(newFilterOptions);
    }
  }, [options, filter, column]);

  useEffect(() => {
    if (audienceFilter) {
      const values = audienceFilter.values.map((item: string) => ({ [audienceFilter.member as string]: item }));
      setValue([ ...values ]);
    }
  }, [audienceFilter]);

  useEffect(() => {
    if(searchValue) {
      loadOptions(searchValue, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const getFilterLabel = (option: any): string => {
    if (!filter.columnType.startsWith('url')) {
      if (typeof option === 'string') {
        return option;
      } else {
        return option[filter.value] ? option[filter.value] : 'NULL';
      }
    } else {
      if (typeof option === 'string') {
        return option.split('-*-')[0];
      } else {
        return option[filter.value].includes('-*-') ? option[filter.value].split('-*-')[0] : option[filter.value];
      }
    }
  };

  const CustomPaper = (props: any) => {
    if (loading) {
      return (
        <Paper {...props}>
          <SearchFilterIndicator size="regular" />
        </Paper>
      );
    }
    if (searchError) {
      return (
        <Paper {...props}>
          <LoadingFilterError />
        </Paper>
      );
    }
    if (filterSearched && !filterOptions.length) {
      return (
        <Paper {...props}>
          <EmptyFilter />
        </Paper>
      );
    }
    return <Paper sx={{ width: 'fit-content', maxWidth: '330px', minWidth: '200px' }} {...props} />;
  };

  return (
    <Autocomplete
      multiple
      freeSolo
      className='u-w-200-px adv-filter-input'
      options={
        loading ? [{}] :
          searchError ? [{}] :
            filterSearched && !filterOptions.length ? [{}] :
              filterOptions
      }
      disabled={!column}
      value={value}
      getOptionLabel={(item: string) => {
        return item[filter.value] ? `${item[filter.value]}` : '';}
      }
      isOptionEqualToValue={(option: any, value: any) => option[filter.value] === value[filter.value]}
      onChange={(event: React.ChangeEvent<{}>, items: any, reason: AutocompleteChangeReason) => handleChangeFilterValue(items, reason)}
      PaperComponent={CustomPaper}
      renderOption={(props: any, item: any, { selected }) => {
        return (
          <Box
            className='u-flex u-flex-align-center string-filter-options'
            key={Math.random()}
          >
            <MenuItem
              value={item}
              {...props}
              className='u-w-100'
              selected={selected}
            >
              {filter!.columnType !== 'url' ? `${item[filter!.value] ? item[filter!.value] : 'NULL'}` :
                `${item[filter!.value] ? item[filter!.value].split('-*-')[0] : 'NULL'}`}
            </MenuItem>
          </Box>
        );
      }}
      renderTags={(tagValue) => [
        ...tagValue.map((option, index) => (
          <Chip
            key={index}
            color='primary'
            label={getFilterLabel(option)}
            size='small'
            className='u-mb-2'
            onDelete={
              () => {
                handleChangeFilterValue(value.filter((item: any, itemIndex: number) => itemIndex !== index), 'removeOption');
              }
            }
          />
        )),
        tagValue.length > 1 && (
          <Chip
            key="clear_all"
            label="Clear All"
            onClick={() => handleChangeFilterValue([], 'clear')}
            sx= {{ backgroundColor: '#FFF', border: '1px solid #2F3B4A80' }}
            size="small"
            className='u-ml-4'
          />
        )
      ]}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Select Value'
          variant="outlined"
          size="medium"
          className='autocomplete-label'
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchValue(event.target.value);
          }}
          InputLabelProps={{
            shrink: !!column || searchValue.length > 0
          }}
        />
      )}
    />
  );
};

export default memo(TextFilter);
