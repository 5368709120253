import type { NextPage } from 'next';
import { useState, useEffect, memo } from 'react';
import { TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import { AudienceFilter } from '../../types/Cube.interfaces';
import { Segment, TableColumn } from '../../types/Common.interfaces';

interface Props {
  audienceFilter: AudienceFilter;
  column: TableColumn | null;
  filterSearched: boolean;
  handleChangeDateFilterValue: (formattedDate: string) => void;
  segment?: Segment | null;
  dateFilterChanged?: boolean;
  setDateFilterChanged?: (value: boolean) => void;
}

const DateFilter: NextPage<Props> = ({
  audienceFilter,
  column,
  handleChangeDateFilterValue,
  segment,
  dateFilterChanged,
  setDateFilterChanged
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  useEffect(() => {
    if (audienceFilter.values.length > 0) {
      const date = audienceFilter.values[0];
      setSelectedDate(new Date(date));
    }
  }, [audienceFilter.values]);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    if (date === null) {
      return;
    }
    const formattedDate = format(date, 'MM-dd-yyyy');
    handleChangeDateFilterValue(formattedDate);
  };


  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        label="Select Date"
        className='u-w-200-px adv-filter-input'
        value={selectedDate}
        onChange={handleDateChange}
        disableFuture={true}
        renderInput={(params) => (
          <TextField
            {...params}
            InputLabelProps={{
              shrink: !!column
            }}
            onKeyDown={(event: React.KeyboardEvent)=> {
              event.preventDefault();
              return false;
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default memo(DateFilter);
