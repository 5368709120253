import type { NextPage } from 'next';
import { memo } from 'react';
import { Box, Typography } from '@mui/material';

interface Props {
}

const AdvancedFilterHeader: NextPage<Props> = () => {

  return (
    <Box className='u-flex u-p-32 u-flex-space-between'>
      <Box>
        <Typography
          sx={{ fontSize: '22px' }}
          className='u-mb-8'
        >
          Filter
        </Typography>
        <Box component='div'>Build highly custom segments using the filter query builder.</Box>
      </Box>
      <Box>
        {/* <Button
          startIcon={<VisibilityOutlinedIcon />}
          variant='outlined'
          className='outlined-button u-mr-12 u-ml-16 u-text-transform-none'
          sx={{ backgroundColor: '#6750A41F' }}
        >
          Data Dictionary
        </Button> */}
      </Box>
    </Box>
  );
};

export default memo(AdvancedFilterHeader);
