import type { NextPage } from 'next';
import { memo, useEffect, useState } from 'react';
import { Autocomplete, AutocompleteChangeReason, Box, Chip, MenuItem, TextField } from '@mui/material';
import { AudienceFilter, Filter } from '../../types/Cube.interfaces';
import { TableColumn } from '../../types/Common.interfaces';


interface Props {
  filter: Filter;
  audienceFilter: AudienceFilter;
  column: TableColumn | null;
  filterSearched: boolean;
  handleChangeFilterValue: (value: any, reason: AutocompleteChangeReason) => void;
}

const BooleanFilter: NextPage<Props> = ({
  filter,
  audienceFilter,
  column,
  handleChangeFilterValue,
}) => {
  const [options, setOptions] = useState<{ label: string, value: string }[]>([]);
  const [selectedValues, setSelectedValues] = useState<(string | null)[]>([]);


  useEffect(() => {
    if (filter) {
      const opts = [
        { label: 'True', value: '1' },
        { label: 'False', value: '0' },
        ...(filter.showNulls ? [{ label: 'Is unknown', value: 'null' }] : []),
      ];
      setOptions(opts);
    }
  }, [filter]);

  useEffect(() => {
    if (audienceFilter) {
      setSelectedValues(audienceFilter.values);
    }
  }, [audienceFilter]);

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: (string | { label: string; value: string | null })[],
    reason: AutocompleteChangeReason
  ) => {
    const newValues = newValue.map((nv) => {
      if (typeof nv === 'string') {
        return nv;
      } else if (nv.value === 'null') {
        return null;
      } else {
        return nv.value;
      }
    });
    setSelectedValues(newValues);
    handleChangeFilterValue(newValues, reason);
  };

  const getFilterLabel = (value: string | null): string => {
    if (value === null) {
      return 'Is unknown';
    }
    const option = options.find((opt) => opt.value === value);
    return option ? option.label : '';
  };


  return (
    <Autocomplete
      multiple
      freeSolo
      className='u-w-200-px adv-filter-input'
      options={options}
      disabled={!column}
      value={selectedValues.map((val) => ({ label: getFilterLabel(val), value: val }))}
      getOptionLabel={(option: any) => option.label}
      isOptionEqualToValue={(option: any, value: any) => option.value === value[filter.value]}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select Value"
          variant="outlined"
          size="medium"
          className='autocomplete-label'
          InputLabelProps={{
            shrink: !!column
          }}
        />
      )}
      renderOption={(props: any, item: any, { selected }) => {
        return (
          <Box
            className='u-flex u-flex-align-center'
            key={Math.random()}
          >
            <MenuItem
              value={item.value}
              {...props}
              className='u-w-100'
              selected={selected}
            >
              {item.label}
            </MenuItem>
          </Box>
        );
      }}
      renderTags={(tagValue) => [
        ...tagValue.map((option, index) => (
          <Chip
            key={index}
            color='primary'
            label={option.label}
            size='small'
            className='u-mb-2'
            onDelete={
              () => {
                handleChangeFilterValue(selectedValues.filter((item: any, itemIndex: number) => itemIndex !== index), 'removeOption');
              }
            }
          />
        )),
        selectedValues.length > 1 && (
          <Chip
            key="clear_all"
            label="Clear All"
            onClick={() => handleChangeFilterValue([], 'clear')}
            sx= {{ backgroundColor: '#FFF', border: '1px solid #2F3B4A80' }}
            size="small"
            className='u-ml-4'
          />
        )
      ]}
    />
  );
};

export default memo(BooleanFilter);
