import type { NextPage } from 'next';
import { useState, useEffect, memo } from 'react';
import { AutocompleteChangeReason, TextField } from '@mui/material';
import { AudienceFilter, Filter } from '../../types/Cube.interfaces';
import { TableColumn } from '../../types/Common.interfaces';

interface Props {
  filter: Filter;
  audienceFilter: AudienceFilter;
  column: TableColumn | null;
  filterSearched: boolean;
  handleChangeFilterValue: (items: any, reason: AutocompleteChangeReason) => void;
}

const NumericalFilter: NextPage<Props> = ({
  filter,
  audienceFilter,
  column,
  handleChangeFilterValue,
}) => {
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    if (audienceFilter && filter && audienceFilter.values.length) {
      setValue(audienceFilter.values.map((value: string) => value)[0]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audienceFilter, filter]);

  return (
    <TextField
      inputProps={{ inputMode: 'numeric'}}
      type='number'
      size='medium'
      label='Input a number'
      className='u-w-200-px adv-filter-input'
      value={value}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        handleChangeFilterValue([event.target.value], 'selectOption');
        setValue(event.target.value);
      }}
      InputLabelProps={{
        shrink: value.length > 0 || !!column
      }}
    >
    </TextField>
  );
};

export default memo(NumericalFilter);
