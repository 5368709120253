import type { NextPage } from 'next';
import { memo, useEffect, useMemo, useState } from 'react';
import { Autocomplete, TextField, Select, MenuItem, Box, IconButton, InputLabel,
  FormControl, SelectChangeEvent, AutocompleteChangeReason, InputAdornment, Paper,
  Collapse, List } from '@mui/material';
import { styled } from '@mui/system';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import { Segment, TableColumn } from '../../types/Common.interfaces';
import { AudienceFilter, Filter, Relation } from '../../types/Cube.interfaces';
import { BinaryOperator, Query, ResultSet, UnaryOperator } from '@cubejs-client/core';
import useCubejsApiWrapper from '../../apiHelpers/cubejsWrapper';
import { useBasicFiltersState } from '../../context/basicFiltersState';
import { useDebouncedCallback } from 'use-debounce';
import StringFilter from '../stringFilter/stringFilter';
import TextFilter from '../textFilter/textFilter';
import NumericalFilter from '../advancedNumericalFilter/numericalFilter';
import BooleanFilter from '../advancedBooleanFilter/booleanFilter';
import DateFilter from '../advancedDateFilter/dateFilter';
import PersonaFilter from '../advancedPersonaFilter/personaFilter';
import BooleanPredefinedFilter from '../booleanPredefinedFilter/booleanPredefinedFilter';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

interface Props {
    index: number;
    groupIndex: number | null;
    audienceFilter: AudienceFilter;
    audienceFilters: AudienceFilter[];
    filters: Filter[];
    columns: TableColumn[];
    isInGroup: boolean;
    segment?: Segment;
    setAudienceFilters: (value: AudienceFilter[]) => void;
    handleDeleteFilter: (index: number) => void;
    onUpdateFilterInGroup: (groupIndex: number, filterIndex: number, updatedFilter: AudienceFilter, shouldTriggerCalls: boolean) => void;
    setFilterChanged?: (value: boolean) => void;
  }

const AdvancedFilterItem: NextPage<Props> = ({ index, groupIndex, filters, audienceFilter, audienceFilters, columns, isInGroup,
  setAudienceFilters, segment, handleDeleteFilter, onUpdateFilterInGroup, setFilterChanged }) => {
  const [operator, setOperator] = useState<BinaryOperator>('equals');
  const [filter, setFilter] = useState<Filter | null>(null);
  const [openGroups, setOpenGroups] = useState<Record<string, boolean>>({});
  const [stringOperatorOptions] = useState<Relation[]> ([
    { value: 'equals', title: 'Equals' },
    { value: 'notEquals', title: 'Does not equal' },
    { value: 'contains', title: 'Contains' },
    { value: 'notContains', title: 'Does not contain' },
    { value: 'startsWith', title: 'Starts with' },
    { value: 'notStartsWith', title: 'Does not start with' },
    { value: 'endsWith', title: 'Ends with' },
    { value: 'notEndsWith', title: 'Does not end with' },
  ]);

  const [numberOperatorOptions] = useState<Relation[]> ([
    { value: 'equals', title: 'Is' },
    { value: 'gt', title: 'Greater than' },
    { value: 'gte', title: 'Greater than or equal to' },
    { value: 'lt', title: 'Less than' },
    { value: 'lte', title: 'Less than or equal to' },
  ]);

  const [dateOperatorOptions] = useState<Relation[]> ([
    { value: 'beforeDate', title: 'Before date' },
    { value: 'afterDate', title: 'After date' },
  ]);

  const [personaOperatorOptions] = useState<Relation[]> ([
    { value: 'equals', title: 'Equals' },
    { value: 'notEquals', title: 'Does not equal' },
    { value: 'contains', title: 'Contains' },
    { value: 'notContains', title: 'Does not contain' },
    { value: 'startsWith', title: 'Starts with' },
    { value: 'notStartsWith', title: 'Does not start with' },
    { value: 'endsWith', title: 'Ends with' },
    { value: 'notEndsWith', title: 'Does not end with' },
  ]);

  const [booleanOperatorOptions] = useState<Relation[]> ([
    { value: 'set', title: 'Set' },
    { value: 'notSet', title: 'Not set' },
  ]);

  const [techColumnsOperatorOptions] = useState<Relation[]> ([
    { value: 'equals', title: 'Equals' },
    { value: 'notEquals', title: 'Does not equal' },
  ]);

  const [employeeCountSort] = useState<{ [key: string]: number }>({
    null: 190,
    '1 to 10': 180,
    '1 to 19': 170,
    '11 to 50': 160,
    '20 to 99': 150,
    '51 to 200': 140,
    '100 to 499': 130,
    '201 to 500': 120,
    '500 to 999': 110,
    '501 to 1000': 100,
    '1000 to 4999': 90,
    '1001 to 5000': 80,
    '5000+': 70,
    '5000 to 9999': 60,
    '5001 to 10000': 50,
    '10000+': 40,
    '10001+': 30,
  });
  const [revenueSort] = useState<{ [key: string]: number }>({
    null: 110,
    '<$1M': 100,
    '$1M to <$10M': 90,
    '$10M to <$50M': 80,
    '$50M to <$100M': 70,
    '$100M to <$1B': 60,
    '$1B+': 50,
  });
  const [jobLevelSort] = useState<{ [key: string]: number }>({
    'C-Team': 100,
    'Director': 90,
    'VP': 80,
    'Manager': 70,
    'Staff': 60,
    'Consultant': 50,
  });
  const initialColumn = () => {
    if (audienceFilter?.member) {
      const columnName = audienceFilter.member.replace(/^(COMPANY\.|TAM\.)/, '');
      return columns.find(column => column.columnName === columnName) || null;
    }
    return null;
  };
  const [selectedColumn, setSelectedColumn] = useState<TableColumn | null>(initialColumn);
  const cubejsApiWrapper = useCubejsApiWrapper();
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [searchError, setSearchError] = useState<boolean>(false);
  const [filterSearched, setFilterSearched] = useState<boolean>(false);
  const [searching, setSearching] = useState<boolean>(false);
  const [currentOperatorOptions, setCurrentOperatorOptions] = useState<Relation[]>(stringOperatorOptions);
  const [searchValue, setSearchValue] = useState<string>('');


  useEffect(() => {
    if (audienceFilter?.operator) {
      setOperator(audienceFilter.operator);
    }
  }, [audienceFilter]);

  useEffect(() => {
    if (selectedColumn) {
      const foundFilter = filters.find((filter: Filter) => filter.value.split('.')[1] === selectedColumn.columnName) || null;
      if (foundFilter) {
        setFilter(foundFilter);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedColumn]);

  useEffect(() => {
    if (!selectedColumn) return;
    let operatorOptions = stringOperatorOptions;
    if (selectedColumn.columnType === 'number') {
      operatorOptions = numberOperatorOptions;
    } else if (selectedColumn.columnType === 'date') {
      operatorOptions = dateOperatorOptions;
    } else if (selectedColumn.columnType === 'persona') {
      operatorOptions = personaOperatorOptions;
    }
    else if (isTextFilter(selectedColumn)) {
      operatorOptions = stringOperatorOptions;
    }
    else if (isBooleanOperatorFilter(selectedColumn)) {
      operatorOptions = booleanOperatorOptions;
    }
    else if (isTechColumnsFilter(selectedColumn)) {
      operatorOptions = techColumnsOperatorOptions;
    }
    setCurrentOperatorOptions(operatorOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedColumn]);

  useEffect(() => {
    const groupNames = new Set(columns.map(option => option.columnName.endsWith('_org') ? 'Organization fields' : 'People fields'));
    const initialGroups = {};
    groupNames.forEach(group => {
      initialGroups[group] = true;
    });
    setOpenGroups(initialGroups);
  }, [columns]);

  const sortedSuggestions = useMemo(() => {
    if (!filter) {
      return [];
    }
    const sortingCriteria = filter.value.includes('employee_count_range_org') ? employeeCountSort
      : filter.value.includes('revenue_range_org') ? revenueSort
        : filter.value.includes('job_level_per') ? jobLevelSort
          : null;
    if (sortingCriteria) {
      return [...suggestions].sort((a, b) => {
        const aValue = sortingCriteria[a[filter.value]] || 0;
        const bValue = sortingCriteria[b[filter.value]] || 0;
        return bValue - aValue;
      });
    }
    return suggestions;
  }, [suggestions, filter, employeeCountSort, revenueSort, jobLevelSort]);

  const handleColumnChange = (newValue: TableColumn | null) => {
    setSelectedColumn(newValue);
    const defaultOperator: any =
      newValue?.columnType === 'date' ? 'afterDate' :
        newValue?.columnType === 'boolean operator' ? 'set' :
          'equals';
    setOperator(defaultOperator);
    if (newValue) {
      const prefix = newValue.columnName.endsWith('_org') ? 'COMPANY.' : newValue.columnName.endsWith('_per') ? 'TAM.' : '';
      const newMember = `${prefix}${newValue.columnName}`;
      const newFilterData = { ...audienceFilter, member: newMember, operator: defaultOperator, values: [] };
      if (isInGroup && groupIndex !== null) {
        onUpdateFilterInGroup(groupIndex, index, newFilterData, false);
      } else {
        const updatedAudienceFilters = [...audienceFilters];
        updatedAudienceFilters[index] = newFilterData;
        setAudienceFilters(updatedAudienceFilters);
        if (audienceFilter.values.length) {
          setFilterChanged && setFilterChanged(true);
          setTimeout(() => {
            setFilterChanged && setFilterChanged(false);
          }, 100);
        };
      }
    } else {
      if (isInGroup && groupIndex !== null) {
        const clearedFilterData = { ...audienceFilter, member: '', operator: defaultOperator, values: [] };
        onUpdateFilterInGroup(groupIndex, index, clearedFilterData, false);
      } else {
        const updatedAudienceFilters = [...audienceFilters];
        updatedAudienceFilters[index] = { ...audienceFilter, member: '', operator: defaultOperator, values: [] };
        setAudienceFilters(updatedAudienceFilters);
      }
    }
  };

  const handleOperatorChange = (operator: BinaryOperator) => {
    const newOperator = operator;
    if (isInGroup && groupIndex !== null) {
      const shouldTriggerCalls = !!audienceFilter.values.length;
      onUpdateFilterInGroup(groupIndex, index, { ...audienceFilter, operator: newOperator }, shouldTriggerCalls);
    } else {
      const updatedAudienceFilters = audienceFilters.map((filter: AudienceFilter, i: number) => {
        if (i === index) {
          return { ...filter, operator: newOperator };
        }
        return filter;
      });
      setAudienceFilters(updatedAudienceFilters);
      setFilterChanged && setFilterChanged(true);
      setTimeout(() => {
        setFilterChanged && setFilterChanged(false);
      }, 100);
    }
  };

  const handleChangeFilterValue = (items: any, reason: AutocompleteChangeReason) => {
    const newValues = items.map((item: any) => item === null ? null : (typeof item === 'string' ? item : item[filter!.value]));
    if (isInGroup && groupIndex !== null) {
      const updatedFilter = { ...audienceFilter, values: newValues };
      onUpdateFilterInGroup(groupIndex, index, updatedFilter, true);
    } else {
      switch (reason) {
        case 'createOption':
          break;
        case 'selectOption':
          const updatedFilters = [...audienceFilters];
          const existingFilter = updatedFilters[index];
          if (existingFilter) {
            existingFilter.values = items.map((item: any) => typeof item === 'string' ? item : item[filter!.value]);
            setAudienceFilters(updatedFilters);
          }
          break;
        case 'removeOption':
          const updatedFiltersAfterRemoval = audienceFilters.map((audienceFilter: AudienceFilter, filterIndex: number) => {
            if (filterIndex === index) {
              return { ...audienceFilter, values: newValues };
            } else {
              return audienceFilter;
            }
          });
          setAudienceFilters(updatedFiltersAfterRemoval);
          break;
        case 'clear':
          const updatedAudienceFilters = [...audienceFilters];
          const newAudienceFilter = { ...audienceFilter, values: [] };
          updatedAudienceFilters[index] = newAudienceFilter;
          setAudienceFilters(updatedAudienceFilters);
          break;
      }
      setFilterChanged && setFilterChanged(true);
      setTimeout(() => {
        setFilterChanged && setFilterChanged(false);
      }, 100);
    }
  };

  const handleChangeDateFilterValue = (formattedDate: string) => {
    const updatedFilter = { ...audienceFilter, operator: operator, values: [formattedDate] };
    updateAudienceFilters(updatedFilter);
  };

  const updateAudienceFilters = (updatedFilter: AudienceFilter) => {
    if (groupIndex !== null && isInGroup) {
      onUpdateFilterInGroup(groupIndex, index, updatedFilter, true);
    } else {
      const updatedAudienceFilters = audienceFilters.map((filter, idx) => idx === index ? updatedFilter : filter);
      setAudienceFilters(updatedAudienceFilters);
    }
    setFilterChanged && setFilterChanged(true);
    setTimeout(() => {
      setFilterChanged && setFilterChanged(false);
    }, 100);
  };

  const loadOptionsBasedOnFilter = async (searchValue: string, isTextFilter: boolean = false) => {
    if (!selectedColumn || !filter) return;
    setSearchError(false);
    setSearching(true);
    try {
      const prefix = selectedColumn.columnName.endsWith('_org') ? 'COMPANY.' : selectedColumn.columnName.endsWith('_per') ? 'TAM.' : '';
      const modifiedColumnName = `${prefix}${selectedColumn.columnName}`;
      const isAboutUsOrg = modifiedColumnName === 'COMPANY.about_us_org';
      if (isAboutUsOrg) {
        setSuggestions([{ [filter.value]: searchValue }]);
      } else {
        const searchColumn = selectedColumn.searchTableName && selectedColumn.searchTableColumnName
          ? `${selectedColumn.searchTableName}.${selectedColumn.searchTableColumnName}`
          : null;
        const query: Query = {
          measures: [ filter.searchColumn ? `${filter.searchColumn.split('.')[0]}.count` : `${prefix.toUpperCase()}count` ],
          dimensions: [searchColumn || modifiedColumnName],
          filters: [],
          limit: 100,
        };
        if (searchValue.length > 0) {
          query.filters!.push({
            member: filter.searchColumn ?? filter.value,
            operator: operator === 'startsWith' ? 'startsWith' : 'contains',
            values: [searchValue],
          });
        }
        const resultSet: ResultSet<any> = await cubejsApiWrapper(query);
        const data = resultSet.tablePivot();
        let suggestions = data.map((item) => ({ [filter.value]: item[filter.searchColumn ?? filter.value] ?? '' }));
        if (isTextFilter) {
          if (!suggestions.some(suggestion => suggestion[filter.value] === searchValue)) {
            suggestions = [{ [filter.value]: searchValue }, ...suggestions];
          }
        }
        setSuggestions(suggestions);
      }
      setFilterSearched(true);
    } catch (error) {
      setSuggestions([]);
      setSearchError(true);
      setFilterSearched(true);
    } finally {
      setSearching(false);
    }
  };

  const loadPersonaFilterOptions = async () => {
    try {
      setSearchError(false);
      setSearching(true);
      const query: Query = {
        dimensions: ['PERSONA.persona_type', 'PERSONA.per_persona', 'PERSONA.display_order'],
        order: [['PERSONA.display_order', 'asc']],
      };
      const resultSet: ResultSet<any> = await cubejsApiWrapper(query);
      const data: { label: string, values: string[], displayOrder: number }[] = [];
      resultSet.tablePivot().forEach((item: { [key: string]: string | number | boolean }) => {
        const parentLabel = item['PERSONA.persona_type'] as string;
        const childValue = item['PERSONA.per_persona'] as string;
        const displayOrder = item['PERSONA.display_order'] as number;
        const findParent = data.find((value: { label: string, values: string[], displayOrder: number }) => value.label === parentLabel);
        if (findParent) {
          findParent.values.push(item['PERSONA.per_persona'] as string);
        } else {
          data.push({ label: parentLabel, values: [childValue], displayOrder: displayOrder });
        }
      });
      setSuggestions(data);
    } catch (error) {
      setSuggestions([]);
    } finally {
      setSearching(false);
    }
  };

  const loadPersonaOptions = () => {
    debouncedPersonaFilterOptions();
  };

  const loadOptions = (searchValue = '') => {
    debouncedSearch(searchValue);
  };

  const debouncedSearch = useDebouncedCallback(loadOptionsBasedOnFilter, 700);

  const debouncedFilterChange = useDebouncedCallback(handleChangeFilterValue, 700);

  const debouncedPersonaFilterOptions = useDebouncedCallback(loadPersonaFilterOptions, 700);

  const CustomPaperComponent = (props: any) => (
    <Paper
      {...props}
      sx={{ width: '330px' }}
      className='autocomplete-dropdown '
    />
  );

  const isBooleanOperatorFilter = (selectedColumn: TableColumn): boolean => {
    return selectedColumn.columnType.includes('boolean operator');
  };

  const isTextFilter = (selectedColumn: TableColumn): boolean => {
    return selectedColumn.columnType.includes('text');
  };

  const isTechColumnsFilter = (selectedColumn: TableColumn): boolean => {
    return (
      (
        selectedColumn.columnType === 'string' &&
        selectedColumn.columnName.endsWith('tech_org')) ||
        selectedColumn.columnName === 'revenue_range_org'
    );
  };

  const GroupHeader = styled('div')(() => ({
    position: 'sticky',
    top: '-2px',
    padding: '12px 10px',
    backgroundColor: '#E2E2E6',
    zIndex: 1,
    fontSize: '17px',
    fontWeight: 500
  }));

  const toggleGroup = (groupName: string) => {
    setOpenGroups(prev => ({
      ...prev,
      [groupName]: !prev[groupName]
    }));
  };

  return (
    <Box
      className='u-flex u-my-12'
    >
      <Autocomplete
        className='u-mr-8 u-w-200-px adv-filter-input'
        value={selectedColumn}
        options={columns}
        isOptionEqualToValue={(option: TableColumn, value: TableColumn) => option.columnName === value.columnName}
        renderOption={(props: any, option: TableColumn) => {
          return (
            <Box
              key={option.id}
            >
              <MenuItem
                value={option}
                {...props}
              >
                {option.displayName}
              </MenuItem>
            </Box>
          );
        }}
        PaperComponent={CustomPaperComponent}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          return option.displayName;
        }}
        groupBy={(option) => {
          if (option.columnName.endsWith('_org')) {
            return 'Organization fields';
          } else {
            return 'People fields';
          }
        }}
        renderGroup={(params) => (
          <div key={params.key}>
            <GroupHeader
              className='u-flex u-flex-space-between'
              onClick={() => toggleGroup(params.group)}
            >
              {params.group}
              {openGroups[params.group] ? <ExpandLess /> : <ExpandMore />}
            </GroupHeader>
            <Collapse in={openGroups[params.group] ?? false} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {params.children}
              </List>
            </Collapse>
          </div>
        )}
        renderInput={(params: any) => (
          <TextField
            {...params}
            label='Select Criteria'
            className='autocomplete-label'
            value={searchValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSearchValue(event.target.value);
            }}
            InputLabelProps={{
              shrink: !!selectedColumn || searchValue.length > 0
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position='start'>
                  {selectedColumn && (selectedColumn.columnName.endsWith('_org') ?
                    <ApartmentRoundedIcon
                      sx={{ fontSize: '24px' }}
                    />
                    :
                    <GroupsRoundedIcon
                      sx={{ fontSize: '24px' }}
                    />
                  )}
                </InputAdornment>
              )
            }}
          />
        )}
        onChange={(event: React.ChangeEvent<{}>, newValue: any, reason: AutocompleteChangeReason) => handleColumnChange(newValue)}
      />
      <FormControl>
        <InputLabel
          id='operator-label'
          className='operator-selector-label'
        >
          Select Operator
        </InputLabel>
        <Select
          key={selectedColumn ? selectedColumn.columnName : 'default'}
          variant='outlined'
          id='operator'
          className='u-mr-8 u-w-200-px adv-filter-input'
          value={operator}
          disabled={!selectedColumn || selectedColumn.columnType === 'boolean'}
          onChange={(event: SelectChangeEvent<any>) => {
            handleOperatorChange(event.target.value);
          }}
          labelId='operator-label'
        >
          {currentOperatorOptions.map((option: { title: string, value: BinaryOperator | UnaryOperator }) => (
            <MenuItem key={option.value} value={option.value}>
              {option.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedColumn && filter ? (
        <>
          {selectedColumn && (
            <>
              {selectedColumn.columnType === 'persona' ? (
                <PersonaFilter
                  key={`personaFilter-${index}`}
                  audienceFilter={audienceFilter}
                  filter={filter}
                  options={suggestions}
                  column={selectedColumn}
                  loading={searching}
                  searchError={searchError}
                  handleChangeFilterValue={handleChangeFilterValue}
                  loadOptions={loadPersonaOptions}
                />
              ) : ( selectedColumn.columnType.endsWith('text')) ? (
                <TextFilter
                  key={`textFilter-${index}`}
                  audienceFilter={audienceFilter}
                  filter={filter}
                  options={suggestions}
                  column={selectedColumn}
                  loading={searching}
                  searchError={searchError}
                  filterSearched={filterSearched}
                  handleChangeFilterValue={handleChangeFilterValue}
                  loadOptions={debouncedSearch}
                />
              ) : (selectedColumn.columnType === 'string') ? (
                <StringFilter
                  key={`stringFilter-${index}`}
                  audienceFilter={audienceFilter}
                  filter={filter}
                  options={sortedSuggestions}
                  column={selectedColumn}
                  loading={searching}
                  searchError={searchError}
                  filterSearched={filterSearched}
                  handleChangeFilterValue={handleChangeFilterValue}
                  onOpen={loadOptions}
                  loadOptions={debouncedSearch}
                />
              ) : selectedColumn.columnType === 'number' ? (
                <NumericalFilter
                  key={`numericalFilter-${index}`}
                  audienceFilter={audienceFilter}
                  filter={filter}
                  column={selectedColumn}
                  filterSearched={filterSearched}
                  handleChangeFilterValue={debouncedFilterChange}
                />
              ) : selectedColumn.columnType === 'boolean' ? (
                <BooleanFilter
                  key={`booleanFilter-${index}`}
                  audienceFilter={audienceFilter}
                  filter={filter}
                  column={selectedColumn}
                  filterSearched={filterSearched}
                  handleChangeFilterValue={handleChangeFilterValue}
                />
              ) : isBooleanOperatorFilter(selectedColumn) ? (
                null
              ) : selectedColumn.columnType === 'boolean predefined' ? (
                <BooleanPredefinedFilter
                  key={`booleanPredefinedFilter-${index}`}
                  audienceFilter={audienceFilter}
                  filter={filter}
                  column={selectedColumn}
                  filterSearched={filterSearched}
                  handleChangeFilterValue={handleChangeFilterValue}
                />
              ) : selectedColumn.columnType === 'date' && (
                <DateFilter
                  key={`dateFilter-${index}`}
                  audienceFilter={audienceFilter}
                  column={selectedColumn}
                  filterSearched={filterSearched}
                  handleChangeDateFilterValue={handleChangeDateFilterValue}
                  segment={null}
                />
              )}
            </>
          )}
        </>
      ) : (
        <StringFilter
          key={`stringFilter-${index}`}
          audienceFilter={audienceFilter}
          filter={filter}
          options={sortedSuggestions}
          column={selectedColumn}
          loading={searching}
          searchError={searchError}
          filterSearched={filterSearched}
          handleChangeFilterValue={handleChangeFilterValue}
          onOpen={loadOptions}
          loadOptions={debouncedSearch}
        />
      )}
      <Box
        className='u-flex u-w-50-px u-flex-align-center u-flex-justify-center'
      >
        <IconButton
          onClick={(event: React.MouseEvent<HTMLElement>) => handleDeleteFilter(index)}
        >
          <DeleteOutlineOutlinedIcon fontSize='medium' sx={{ color: '#42474E'}} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default memo(AdvancedFilterItem);