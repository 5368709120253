import type { NextPage } from 'next';
import { ToggleButtonGroup, ToggleButton, Box } from '@mui/material';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

interface Props {
    relation: string;
    onToggle: (value: 'and' | 'or') => void;
  }

const AdvancedFilterGroupRelationSwitch: NextPage<Props> = ({ relation, onToggle }) => {
  const handleRelationChange = (event: React.MouseEvent<HTMLElement>, newRelation: 'and' | 'or') => {
    if (newRelation !== null) {
      onToggle(newRelation);
    }
  };

  return (
    <Box
      className='toggle-button-wrapper u-flex u-flex-direction-column u-flex-justify-center u-my-12 u-px-12'
    >
      <Box className='line-top' />
      <ToggleButtonGroup
        color='primary'
        value={relation}
        exclusive
        onChange={handleRelationChange}
      >
        <ToggleButton
          value='and'
          sx={{ textTransform: 'none' }}
          className='u-w-100-px u-py-4 u-br-20-px'
        >
          {relation === 'and' ? (
            <CheckOutlinedIcon
              sx={{ fontSize: '16px' }}
              className='u-mr-8'
            />
          ): null}
          And
        </ToggleButton>
        <ToggleButton
          value='or'
          sx={{ textTransform: 'none' }}
          className='u-w-100-px u-py-4 u-br-20-px'
        >
          {relation === 'or' ? (
            <CheckOutlinedIcon
              sx={{ fontSize: '16px' }}
              className='u-mr-8'
            />
          ): null}
          Or
        </ToggleButton>
      </ToggleButtonGroup>
      <Box className='line-bottom' />
    </Box>
  );
};

export default AdvancedFilterGroupRelationSwitch;
