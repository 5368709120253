import { NextPage } from 'next';
import { memo, useCallback, useRef } from 'react';
import { Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { AudienceFilter } from '../../types/Cube.interfaces';
import { TableColumn } from '../../types/Common.interfaces';
import AdvancedFilterItem from '../advancedFilterItem/advancedFilterItem';
import AdvancedFilterGroup from '../advancedFilterGroup/advancedFilterGroup';
import AdvancedFilterGroupRelationSwitch from '../advancedFilterGroupRelationSwitch/advancedFilterGroupRelationSwitch';
import { useGlobalState } from '../../context/globalState';
import { useBasicFiltersState } from '../../context/basicFiltersState';

interface Props {
  audienceFilters: AudienceFilter[];
  setAudienceFilters: (filters: AudienceFilter[]) => void;
  setFilterChanged?: (value: boolean) => void;
}

const AdvancedFiltersWrapper: NextPage<Props> = ({
  audienceFilters,
  setAudienceFilters,
  setFilterChanged
}) => {

  const { companyFilters, peopleFilters, peopleDatabaseColumns, companyDatabaseColumns } = useGlobalState();
  const { globalRelation, setGlobalRelation } = useBasicFiltersState();
  const filtersEndRef = useRef<HTMLElement>(null);
  const scrollContainerRef = useRef<HTMLElement>(null);
  const filterColumns = [...companyDatabaseColumns, ...peopleDatabaseColumns].filter(column => column.isFilter);

  const columns = filterColumns.reduce((acc: TableColumn[], current: TableColumn) => {
    const exists = acc.find(item => item.columnName === current.columnName);
    if (!exists) {
      acc.push(current);
    } else {
      const currentHasOrgPrefix = current.displayName.startsWith('Org.');
      const existsHasOrgPrefix = exists.displayName.startsWith('Org.');
      if (existsHasOrgPrefix && !currentHasOrgPrefix) {
        const index = acc.findIndex(item => item.columnName === current.columnName);
        acc[index] = current;
      }
    }
    return acc;
  }, []);

  const handleAddFilter = useCallback(() => {
    const newFilterData: AudienceFilter = {
      member: '',
      operator: 'equals',
      values: [],
    };
    const newFilters = [...audienceFilters, newFilterData];
    setAudienceFilters(newFilters);
    if (filtersEndRef.current) {
      filtersEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setTimeout(() => {
        if (scrollContainerRef.current) {
          scrollContainerRef.current.scrollTop += 120;
        }
      }, 10);
    }
  }, [setAudienceFilters, audienceFilters]);

  const handleAddFilterGroup = useCallback(() => {
    const newFilter: AudienceFilter = {
      member: '',
      operator: 'equals',
      values: [],
    };
    const newGroup: AudienceFilter = {
      is_group: true,
      relation: 'and',
      children: [newFilter],
      operator: 'equals',
      values: [],
    };
    setAudienceFilters([...audienceFilters, newGroup]);
    if (filtersEndRef.current) {
      filtersEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setTimeout(() => {
        if (scrollContainerRef.current) {
          scrollContainerRef.current.scrollTop += 80;
        }
      }, 10);
    }
  }, [setAudienceFilters, audienceFilters]);

  const handleGlobalRelationChange = useCallback((newRelation: 'and' | 'or') => {
    setGlobalRelation(newRelation);
    setFilterChanged!(true);
    setTimeout(() => {
      setFilterChanged!(false);
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setGlobalRelation]);

  const handleDeleteFilterGroup = useCallback((groupIndex: number) => {
    const newFilters = audienceFilters.filter((filter: AudienceFilter, index: number) => index !== groupIndex);
    setAudienceFilters(newFilters);
    if (setFilterChanged) {
      setFilterChanged(true);
      setTimeout(() => setFilterChanged(false), 100);
    }
  }, [setAudienceFilters, audienceFilters, setFilterChanged]);

  const handleAddFilterToGroup = useCallback((groupIndex: number, newFilter: AudienceFilter) => {
    const newFilters = [...audienceFilters];
    const targetGroup = newFilters[groupIndex];
    if (targetGroup && targetGroup.is_group) {
      targetGroup.children = [...(targetGroup.children || []), newFilter];
      newFilters[groupIndex] = targetGroup;
    }
    setAudienceFilters(newFilters);
  }, [setAudienceFilters, audienceFilters]);

  const handleRemoveFilter = useCallback((index: number, childIndex = null) => {
    const newFilters = [...audienceFilters];
    if (childIndex !== null && newFilters[index].children) {
      newFilters[index].children!.splice(childIndex, 1);
    } else {
      newFilters.splice(index, 1);
    }
    setAudienceFilters(newFilters);
    if (setFilterChanged) {
      setFilterChanged(true);
      setTimeout(() => setFilterChanged(false), 100);
    }
  }, [setAudienceFilters, audienceFilters, setFilterChanged]);

  const handleDeleteFilterInGroup = useCallback((groupIndex: number, filterIndex: number) => {
    const newFilters = [...audienceFilters];
    const group = newFilters[groupIndex];
    if (group && group.children) {
      group.children.splice(filterIndex, 1);
      if (group.children.length === 0) {
        newFilters.splice(groupIndex, 1);
      }
    }
    setAudienceFilters(newFilters);
    if (setFilterChanged) {
      setFilterChanged(true);
      setTimeout(() => setFilterChanged(false), 100);
    }
  }, [setAudienceFilters, audienceFilters, setFilterChanged]);


  const handleUpdateFilterInGroup = (groupIndex: number, filterIndex: number, updatedFilter: AudienceFilter, shouldTriggerCalls: boolean) => {
    const newFilters = audienceFilters.map((filter: AudienceFilter, i: number ) => {
      if (i === groupIndex && filter.is_group) {
        return {
          ...filter,
          children: filter.children!.map((child: AudienceFilter, j: number) => j === filterIndex ? updatedFilter : child)
        };
      }
      return filter;
    });
    setAudienceFilters(newFilters);
    if (setFilterChanged && shouldTriggerCalls) {
      setFilterChanged(true);
      setTimeout(() => setFilterChanged(false), 100);
    }
  };

  const handleGroupRelationChange = (newRelation: 'and' | 'or', groupIndex: number) => {
    const updatedAudienceFilters = [...audienceFilters];
    if (updatedAudienceFilters[groupIndex] && updatedAudienceFilters[groupIndex].is_group) {
      updatedAudienceFilters[groupIndex].relation = newRelation;
      setAudienceFilters(updatedAudienceFilters);
    }
    if (setFilterChanged) {
      setFilterChanged(true);
      setTimeout(() => setFilterChanged(false), 100);
    }
  };


  return (
    <Box
      ref={scrollContainerRef}
      className='u-mx-40 u-pt-40 u-overflow-auto u-flex u-flex-direction-column'
      sx={{ maxHeight: '-webkit-fill-available' }}
    >
      <Box className='u-flex'>
        {audienceFilters.length > 1 ? (
          <AdvancedFilterGroupRelationSwitch
            relation={globalRelation}
            onToggle={handleGlobalRelationChange}
          />
        ) : null }
        <Box>
          <Box>
            {audienceFilters.map((audienceFilter: AudienceFilter, index: number) => {
              if (audienceFilter.is_group && audienceFilter.children?.length) {
                return (
                  <AdvancedFilterGroup
                    key={`group-filter-${index}}`}
                    groupIndex={index}
                    audienceFiltersGroup={audienceFilter.children}
                    audienceFilters={audienceFilters}
                    groupRelation={audienceFilter.relation!}
                    columns={columns}
                    filters={[...companyFilters, ...peopleFilters]}
                    handleDeleteFilterGroup={() => handleDeleteFilterGroup(index)}
                    handleAddFilterToGroup={handleAddFilterToGroup}
                    handleDeleteFilterInGroup={handleDeleteFilterInGroup}
                    setAudienceFilters={setAudienceFilters}
                    onUpdateFilterInGroup={handleUpdateFilterInGroup}
                    setFilterChanged={setFilterChanged}
                    handleGroupRelationChange={handleGroupRelationChange}
                  />
                );
              } else {
                return (
                  <AdvancedFilterItem
                    key={`filter-${index}}`}
                    index={index}
                    groupIndex={null}
                    isInGroup={false}
                    filters={[...companyFilters, ...peopleFilters]}
                    audienceFilter={audienceFilter}
                    audienceFilters={audienceFilters}
                    columns={columns}
                    handleDeleteFilter={() => handleRemoveFilter(index)}
                    setAudienceFilters={setAudienceFilters}
                    onUpdateFilterInGroup={handleUpdateFilterInGroup}
                    setFilterChanged={setFilterChanged}
                  />
                );
              }
            })}
          </Box>
          <Box ref={filtersEndRef as React.RefObject<HTMLDivElement>} />
        </Box>
      </Box>
      <Box
        className='u-py-20 u-sticky'
        sx={{ bottom: '0', backgroundColor: '#fff', zIndex: '1' }}
      >
        <Button
          disableElevation
          startIcon={<AddIcon />}
          className='contained-button u-mr-16 u-text-transform-none filter-action-button-contained u-fw-400'
          variant='contained'
          onClick={handleAddFilter}
        >
          Add Filter
        </Button>
        <Button
          disableElevation
          startIcon={<AddIcon />}
          className='contained-button u-text-transform-none filter-action-button-contained u-fw-400'
          variant='contained'
          onClick={handleAddFilterGroup}
        >
          Add Filter Group
        </Button>
      </Box>
    </Box>
  );
};

export default memo(AdvancedFiltersWrapper);